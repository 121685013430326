import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { GatsbyImage } from 'gatsby-plugin-image';
import { SRLWrapper } from 'simple-react-lightbox';

export default function Products({ data, location }) {
	const { allMarkdownRemark } = data;
	const edges = allMarkdownRemark.edges;
	const edge = edges[0];
	const galleryProducts = edge.node.frontmatter.products;
	const optionsLightbox = {
		caption: {
			showCaption: false,
		},
		buttons: {
			showDownloadButton: false,
		},
		thumbnails: {
			showThumbnails: false,
		},
	};

	return (
		<Layout location={location} lang="en">
			<Seo title="Products" />
			<div className="my-12 pt-12">
				<h2 className="text-center text-4xl tracking-tight text-gray-900 dark:text-white sm:text-5xl sm:leading-none">
					Welcome to our Products page
				</h2>
			</div>
			<div className="mb-12 py-12">
				<SRLWrapper options={optionsLightbox}>
					<div
						className="2xl:grid-cols-5 grid lg:grid-cols-4 
				md:grid-cols-3 sm:grid-cols-2"
					>
						{galleryProducts.map((product, index) => (
							<div key={index} className="flex-1 bg-black cursor-pointer">
								<GatsbyImage
									className="hover:opacity-40"
									image={product.childImageSharp.gatsbyImageData}
									alt={product.name}
									style={{ height: '100%', width: '100%', marginBottom: 0 }}
									imgStyle={{ objectFit: 'inherit' }}
								/>
							</div>
						))}
					</div>
				</SRLWrapper>
			</div>
		</Layout>
	);
}

export const productsQuery = graphql`
	query {
		allMarkdownRemark(
			filter: {
				html: { eq: "" }
				frontmatter: {
					arts: { elemMatch: { sourceInstanceName: { ne: "art" } } }
				}
			}
		) {
			edges {
				node {
					frontmatter {
						title
						products {
							name
							publicURL
							id
							childImageSharp {
								gatsbyImageData(formats: [AUTO, WEBP, AVIF])
							}
						}
					}
				}
			}
		}
	}
`;
